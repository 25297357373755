import React from 'react'

import './../Blogs/Blogs.css' 

function Blogs() {
  return (
    

<div className="pdf-container">
      <iframe
        title="Resume PDF"
        src="https://drive.google.com/file/d/1TNf3wtDZbN1hfQlXxYdhWD4BKjnkmIiw/preview"
        width="100%"
        height="100%" // 
      />
    </div>

     
  )
}

export default Blogs